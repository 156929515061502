<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="layout-top-spacing">
        <div class="layout-spacing">
          <div class="widget-content widget-content-area br-6">
            <b-table
              id="logs-table"
              :items="logs"
              :fields="fields"
              :busy="isBusy"
              bordered
              hover
              responsive
            >
              <template #table-busy>
                <div class="text-center text-info my-2">
                  <b-spinner class="align-middle mr-3"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(causer)="data">
                {{
                  data.item.causer_id
                    ? `${data.item.causer.firstname} ${data.item.causer.lastname}`
                    : 'system'
                }}
              </template>

              <template #cell(created_at)="data">
                {{ formatFancyDateFull(data.item.created_at) }}
              </template>

              <template #cell(actions)="data">
                <b-btn
                  size="sm"
                  @click="openChanges(data.item)"
                  v-if="data.item.properties.old && data.item.properties.attributes"
                  squared
                >
                  Open Changes
                </b-btn>
              </template>
            </b-table>

            <b-pagination-nav
              v-if="logData && logData.data"
              :link-gen="linkGen"
              :number-of-pages="logData.last_page"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
              limit="10"
              last-number
              use-router
            ></b-pagination-nav>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="changes-modal"
      size="xl"
      :title="changes.title"
      centered
      hide-footer
    >
      <b-table-simple
        bordered
        hover
        responsive
      >
        <b-thead head-variant="dark">
          <b-tr>
            <b-th>Attribute</b-th>
            <b-th>Old Data</b-th>
            <b-th>New Data</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(change, index) in changes.data" :key="index">
            <b-td>{{ index }}</b-td>
            <b-td>{{ change.old }}</b-td>
            <b-td>{{ change.new }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-modal>
  </div>
</template>

<script>
import { http } from "@/utils/http";
import { endpoints } from "@/utils/endpoints";
import pagination from "@/mixins/pagination";

export default {
  name: "ActivityLogs",

  data() {
    return {
      isBusy: false,
      fields: [
        {
          key: "index",
          label: "#"
        },
        {
          key: "causer",
          label: "By",
        },
        {
          key: "description",
          label: "Description",
        },
        {
          key: "created_at",
          label: "Date",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      logData: null,
      query: "",
      changes: {
        title: "",
        data: null,
      },
    }
  },

  mixins: [pagination],

  computed: {
    logs() {
      return this.logData?.data;
    }
  },

  methods: {
    fetchAll() {
      this.isBusy = true;
      http
        .get(endpoints.FETCH_ACTIVITY_LOGS + `?${this.query}`)
        .then((response) => {
          this.logData = response;
        })
        .catch((error) => {
          this.$toast.error(error.response?.data?.message || error.response?.message);
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    openChanges(activity) {
      let result = {};

      let oldAttributes = activity.properties?.old;
      let newAttributes = activity.properties.attributes;

      for (var key in oldAttributes) {
        if (!newAttributes.hasOwnProperty(key) || newAttributes[key] !== oldAttributes[key]) {
          result[key] = {
            old: oldAttributes[key],
            new: newAttributes[key],
          };
        }
      }

      this.changes.title = activity.description;
      this.changes.data = result;

      this.$bvModal.show('changes-modal');
    }
  }
}
</script>
